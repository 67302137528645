import ScrollMagic from "scrollmagic";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger.js";
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap"; 
ScrollMagicPluginGsap(ScrollMagic, gsap);
// import { ScrollMagicPluginIndicator} from "scrollmagic-plugins";
import "jquery";
import "fullpage.js";
import Barba from "barba.js";
import "@vimeo/player";
import objectFitImages from 'object-fit-images';
import _, { map } from 'underscore';
// import barba from '@barba/core'; //newer version of barba.js
// gsap.defaultOverwrite = false;
gsap.registerPlugin(ScrollTrigger);

// "bootstrap-sass": "3.3.6",
//     "fullpage.js": "~2.9.4",
//     "gsap": "~1.19.1",
//     "scrollmagic": "~2.0.5",
//     "vimeo-player-js": "~2.1.0"
/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        var body = $('body');
        // set object fit polyfill on any image with the class of 'object-fit'
        objectFitImages('img.object-fit');

        // set up click handler on clicking the menu button for slide out
        var menu = $('#menu'),
            menuButton = $('.menuButton'),
            links = menuButton.find('span');

        menuButton.on('click', function() {

          menu.toggleClass('active');

        // match media here
          if(window.matchMedia("(min-width: 768px)").matches) {
            menu.css({"position": "fixed", "height": "100vh"});
          } else {
              menu.css({"position" : "absolute", "height" : "100vh"});
          }


          links.toggleClass('open');
          $(this).toggleClass('open');

          if ( menu.hasClass('active') ) {

            var menuTimeline = new gsap.timeline({ paused: true }),
                menuCanvas = $('#menu'), menuLinks = $('#menu-primary-navigation').find('>li');

            menuTimeline.from(menuLinks, {duration: 0.3, opacity: 0, x: 100, ease: "circ.easeOut" }, 0.1);

            $('ul.social-list path').css({transition: 'fill 0.5s ease-in-out', fill: "white"});

            menuTimeline.play();

            if(body.hasClass('scrolled')) {
              body.removeClass('scrolled');
            }

            if(body.hasClass('home')) {
              $.fn.fullpage.setAllowScrolling(false); // disable fullpage when menu is open on home page.
            }

          } else {

            $('ul.social-list path').css({transition: 'fill 0.5s ease-in-out', fill: "black"});

            if(body.hasClass('home')) {
              $.fn.fullpage.setAllowScrolling(true); // enable fullpage when menu is closed on home page.
            }

            if(!body.hasClass('scrolled') && $(window).scrollTop() > 70 ) {

              body.addClass('scrolled');


            }

          }

        });

        if(!body.hasClass('home')) {
          $(document).on('scroll', _.throttle( function() {
            if($(window).scrollTop() > 70 && !$('#menu').hasClass('active')) {
              $('body').addClass('scrolled');
            } else {
              $('body').removeClass('scrolled');
            }
          }, 500));
        }

        /**
         * ScrollTop Button Functionality
         */

        var scrollTopButton = $('#scrollToTop');

        function scrollButton(triggerEl) {
          var trigger = $(triggerEl)[0];


          $(document).on('scroll', _.throttle( function() {
            var windowTop = $(window).scrollTop(), // distance window is scrolled from top
              ElTop = $(trigger).offset().top, // distance element's top is from document top.
              ElHeight = $(trigger).height(); // the element's height
            if(windowTop >= (ElTop + ElHeight) ) {
              $('#scrollToTop').addClass('show');
            } else {
              $('#scrollToTop').removeClass('show');
            }
          }, 500));


          $('#scrollToTop').on('click', function() {
            $('html, body').animate({ scrollTop: 0 });
          });

          var tl = new gsap.timeline({paused: true}), arrow = $('#scrollToTop g.arrow');

          tl.to($(arrow), {duration: 0.2, y: -10, opacity: 0}).to($(arrow), {duration: 0.2, y: 10}).to($(arrow), {duration: 0.2, y:0, opacity: 1});

          $('#hotspot').on('mouseenter', function() {
            tl.play();
            tl.restart();
          });

        }

        if( scrollTopButton.length ) { // check for the button before running the function

          var arrayCheck = [
            $('header.project-header'),
            $('.partners'),
            $('.category-section')
          ];

          $.each(arrayCheck, function() {
            if(this.length) {
              scrollButton(this);
              return false;
            }
          });

        }

        var reveal = $('.reveal');
       // console.log(reveal);

        var matchSize = {};

        $(window).on('load resize', _.debounce(function(){
          if(window.matchMedia("(min-width: 768px)").matches) {
            matchSize.matchedSize = "true";
          } else {
            matchSize.matchedSize = "false";
          }
    //      console.log(matchSize.matchedSize);
        }, 200));


        function revealShowHide() {
          var trigger = $('img.single-project-thumb'),
            headerMark = trigger.offset().top,
            scrollTopMark = $(window).scrollTop();
          if(scrollTopMark > headerMark) {
            $('footer.main-footer, .reveal').css({opacity: 1});
          } else {
            $('footer.main-footer, .reveal').css({opacity: 0});
          }
        }

        function setUpReveal(state) {
          var revealFirst = reveal[0],
            revealAreaHeight = $(reveal[0]).height(),
            footer = $('footer.main-footer'),
            footerHeight = $('#footer').outerHeight(),
            previous = $('.reveal').prev(),
            wraps = $('.entry-content, .wrap'),
            article = $('article.min_design_project');

          if(state === "add") {
            $(wraps).css({zIndex: 1});
            $(revealFirst).css({position: "fixed", bottom: footerHeight, zIndex: -1, opacity: 0});
            $('header.project-header').css({backgroundColor: "white"});
            $(article).css({marginBottom: (revealAreaHeight + footerHeight)});
            $(previous).css({zIndex: 1, backgroundColor: "white", boxShadow: "0px 10px 7px -7px rgba(0,0,0,0.2)", paddingBottom: 150, position: "static"});
            $(footer).css({position: "fixed", bottom: 0, zIndex : -1, opacity: 0});
          } else if(state === "remove") {
            $(wraps).css({zIndex: 1});
            $(revealFirst).css({position: "relative", bottom: "auto", opacity: 1});
            $(article).css({marginBottom: 0});
            $(previous).css({zIndex: 1, backgroundColor: "white", boxShadow: "none", paddingBottom: 40, position: "relative"});
            $(footer).css({position: "relative", bottom: "auto", zIndex : -1, opacity: 1, float:"left"});

          }

        }

        // $(window).on('load resize', _.debounce(function(){
        //   if( reveal.length && matchSize.matchedSize === "true" ) {
        //     setUpReveal("add");
        //     revealShowHide();
        //     $(document).on('scroll', _.throttle(revealShowHide, 50));
        //   } else {
        //     setUpReveal("remove");
        //   }
        // }, 200));


        // un-stick scroll button when reaching bottom of page.

        $(window).on('scroll', function() {
          var content, offset, margin;
          if(reveal.length) {
            content = $('#entry-content');
            offset = (content.height() + content.offset().top + 40 ) - ($(window).scrollTop() + $(window).height());

            if(matchSize.matchedSize === "true") {
              margin = parseInt($(content)[0].style.marginBottom) + 40;
            } else {
              margin = 36;
            }


          } else {
            content = $('.main');
            margin = 170;
            offset = (content.height() + content.offset().top + 90 ) - ($(window).scrollTop() + $(window).height());

          }

          if( offset <= 0 ) {
            $('#scrollToTop').css({position: "absolute", bottom: margin});
          } else {
            $('#scrollToTop').css({position: "fixed", bottom: "36px"});
          }
        });


        // project tile rollover
        var projectTile = $('.project-tile-container:not(.category)');

        if(projectTile.length && !body.hasClass('projects')) {
          $.each(projectTile, function() {

            var newTimeLine = new gsap.timeline({paused : true});

            newTimeLine.to($(this), {duration: 0.2, opacity: 1}).to($(this).find('.project-tile-title'), {duration: 0.2, opacity: 1}, "-=0.1");

            $(this)
            .on('mousenter', function() { newTimeLine.play(); })
            .on('mouseleave', function() { newTimeLine.reverse(); });

          });
        }
        var revealEl = $('.reveal-title'),
          title = $('.title h1');
        // gsap.to(revealEl, {duration: 0.5, scaleX : 0, ease: "power1.easeIn", delay:0.5 });

        //colorChanger

       var backgroundColor = $('#section-head').data('background-color');

       if (body.hasClass('projects')) {
         $(document).on('scroll', _.throttle( function() {
           if($(window).scrollTop() >= 5) {
             $('body').css({"backgroundColor" : backgroundColor, "transition" : "all 0.3s "});
             $('.page-title, .project-category-title, .project-tags a, .project-category-list').addClass("change-color");
           } else {
             $('body').css({"backgroundColor" : "white", "transition" : "all 0.3s ease-out"});
             $('.page-title, .project-category-title, .project-tags a, .project-category-list').removeClass("change-color");
           }
         }, 500 ));
       }

       // Min Design logo animation
        var tl = new gsap.timeline({repeat: -1, paused: true});
        tl.to('.dot', {
          duration: 1,
          y: 108,
          ease: "power3.easeIn"
        })
          .to('.minBlanker',{
          duration: 1,
          y: 170
        })
          .to('.dot',{
          duration: 1,
          y: 0,
          ease: "power3.easeOut"
        }, '-=1')
          .to('.letterI',{
          duration: 1,
          height: '-=80',
          y: '+=80'
        }, 1)
          .set('.letterI', {
          duration: 1,
          y: 0,
          height:77.8
        })
          .from('.letterI', {
          duration: 0.2,
          alpha: 0,
          immediateRender: false
        })
          .from('.letterICopy', {
          duration: 0.5,
          height: 0
        }, 1.4);

        $('#logo').hover(
          function() {
            tl.play();
            tl.eventCallback("onRepeat", null);
          }, function() {
            tl.eventCallback("onRepeat", function(){
              tl.pause();
            });
          }
        );

        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

        var sections = $('.storyContentItem'), // all story contents (sections)
          media = $('.itemContainer'), // all media selection
          scrollingSpeed = 700, // scrollspeed var for fullpage-js and tweens
          scrollButton = $('#downScrollButton'),
          scrollButtonUp = $('#upScrollButton'); // svg scroll prompt el

        if(scrollButton) {
          scrollButton.addClass( "arrow-animation" );
        }

        /**
         * function to set up the tween for whatever the slide animation should be. Pulls from data attr of element
         * @param nextIndex = index of the next section. Needs to be adjusted for 0 index array, fullpage js starts the count at 1.
         */
        function tweenSlide(nextIndex) {
          // console.log('nextIndex: ',nextIndex);
          let dataTarget, tl, targetImage, upComingSection, dataColor, type, dataGradient, backgroundString;

          upComingSection = $(sections[nextIndex]);
          // console.log('upComingSection: ', upComingSection, 'nextIndex.index: ', nextIndex.index, 'dataTarget: ', dataTarget, 'dataGradient: ', dataGradient)
          dataTarget = upComingSection.data('target');
          dataGradient = upComingSection.data('gradient');
          backgroundString = '';
          if(dataGradient !== undefined) {
            backgroundString = dataGradient;
          } else {
            backgroundString = 'linear-gradient(30deg, #000 25%, #000 100%)';
          }
          type = upComingSection.data('type');
          console.log(type);

          if(type === 'news') {
            // opacity .5 of thumbnail image and background  color shining through
            // i suppose we can do this with only animating the image in to 50% and doing the color change
            targetImage = $('.itemContainer#' + dataTarget)[0];

            tl = new gsap.timeline({delay:(scrollingSpeed / 1000) / 2});

            tl.fromTo(targetImage, {opacity: 0}, {opacity: 0.2, duration: (scrollingSpeed / 1000) / 2}, "sync")
              .to($(media).not(targetImage), {opacity: 0, duration: (scrollingSpeed / 1000) / 2}, "sync")
              .to($('body'), {backgroundImage: backgroundString, duration: (scrollingSpeed / 1000) / 2}, "sync -= .2");

          } else if(type === 'message') {
            // either a solid color or a graident background
            tl = new gsap.timeline();
            var sectionImages = upComingSection.find('img');

            tl.to($(media), {opacity: 0, duration: (scrollingSpeed / 1000) / 2}, "colorChange")
              .to($('body'), {backgroundImage: backgroundString, duration: (scrollingSpeed / 1000) / 2}, "colorChange -= .2")
              .fromTo($(sectionImages), {opacity: 0}, {opacity: 1, duration: (scrollingSpeed / 1000) / 2});

          } else if(type === 'photo') {
            targetImage = $('.itemContainer#' + dataTarget)[0];
            tl = new gsap.timeline({delay:(scrollingSpeed / 1000) / 2});

            tl.fromTo(targetImage, {opacity: 0}, {opacity: 1, duration: (scrollingSpeed / 1000) / 2}, "sync")
              .to($(media).not(targetImage), {opacity: 0, duration: (scrollingSpeed / 1000) / 2}, "sync")
              .to($('body'), {backgroundImage: backgroundString, duration: (scrollingSpeed / 1000) / 2}, "sync -= .2");
            // a solid photo
          }

          // locate upcoming section and change body color.
          // direction dependant



        }

        // init fullpage

        $('.fullpage').fullpage({
          licenseKey: 'D94LK-PGQD8-3AMKK-74JX8-YUWCM',
          scrollingSpeed: scrollingSpeed,
          continuousVertical: true,

          afterRender : function() {
            // to make the first tween happen
            var tl = new gsap.timeline(),
                targetImage,
                dataTarget = $(sections[0]).data('target'),
                thisSlideGradient = $(sections[0]).data('gradient'),
                thisSlideType = $(sections[0]).data('type');

            if(thisSlideType === 'news') {
              $('body').css({background: thisSlideGradient});
              targetImage = $('.itemContainer#'+dataTarget)[0];
              tl.to($(targetImage), {opacity: 0.2 , duration: 0.5});

            } else if(thisSlideType === 'message') {
              $('body').css({background: thisSlideGradient});
              
            } else if(thisSlideType === 'photo') {
              targetImage = $('.itemContainer#'+dataTarget)[0];
              tl.to($(targetImage) , {opacity: 1, duration: (scrollingSpeed / 1000) / 2});
            }

          },

          onLeave : function(origin, destination, direction) {
            // console.log('nextIndex.item: ',nextIndex.item, 'index from func: ',index);
            // console.log('index gradient attr: ',$(index.item).data('gradient'));
            // fade out scroll button
          //  console.log('index.index', index.index);
            if(destination.index === 1) {
              scrollButton.removeClass( "arrow-animation" );
              scrollButtonUp.css( "visibility", "visible" );
            }

    //         gsap.to( $('body'), { duration: ( ( scrollingSpeed/ 1000) / 2 ), backgroundColor: "rgba(0,0,0,1)" });
          //  console.log('destination.index: ',destination.index);
            tweenSlide(destination.index);

          }

        });

        var runTimer = false;

        var fullPageTimer =  setInterval(function(){
              $.fn.fullpage.moveSectionDown();
            }, 5000);


        // click event for the scrollbutton
        // animation controlled with css @keyframe
        scrollButton.on('click', function() {
          $.fn.fullpage.moveSectionDown();

          scrollButton.removeClass( "arrow-animation" );
          scrollButtonUp.css( "visibility", "visible" );
          clearInterval(fullPageTimer);
        });

        scrollButtonUp.on('click', function() {
          $.fn.fullpage.moveSectionUp();
          clearInterval(fullPageTimer);
        });

        $(document).keydown(function(e) {
          if(e.keyCode === 40 || e.keyCode === 38 || e.keyCode === 32 ) {
            clearInterval(fullPageTimer);
          }
        });

        $(document).on('wheel touchstart touchmove', function(e) {
          clearInterval(fullPageTimer);
        });



      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // page, note the change from about-us to about_us.
    'projects': {
      init: function() {

        var projectTiles = $('.project-tile-container:not(.category)');

        $.each(projectTiles, function() {

          var timeline = new gsap.timeline({paused: true});

          $(this).hover(
            function() {
              timeline.play();
            }, function() {
              timeline.reverse();
            }
          );

        });


        // JavaScript to be fired on the featured projects page
      }
    },
    //
    'all_projects': {
      init: function() {

        function checkTotal() {
          var projectCount = ajaxpagination.total,
            currentProjects = $('.project-tile-container').length;

          console.log(projectCount, currentProjects);

          if(projectCount <= currentProjects ) {
            $('#load-more-btn').hide();
          }
        }


        checkTotal();


        $(document).on( 'click', '#load-more-btn', function( event ) {
          event.preventDefault();

          $.ajax({
            url: ajaxpagination.ajaxurl,
            type: 'post',
            data: {
              action: 'ajax_pagination',
              page: ajaxpagination.page
            },

            beforeSend: function() {

              var loadMoreBtn = $("#load-more-btn"),
                code = '<span> Loading ' +
                  '<span class="glyphicon glyphicon-refresh spinner"></span>' +
                  '</span>';

              loadMoreBtn.addClass('loading');
              loadMoreBtn.removeClass("default");
              loadMoreBtn.html(code);

            },

            success: function( result ) {

              var data = $(result),
                loadMoreBtn = $("#load-more-btn"),
                code;


              // check to make sure anything was returned from the query
              if(data.length) {


                // if there's data
                code = '<span class="animate"> More Projects ' +
                  '<span class="first animation"></span> ' +
                  '<span class="second animation"></span> ' +
                  '</span>';

                loadMoreBtn.addClass("default");
                loadMoreBtn.removeClass("loading");
                loadMoreBtn.html(code);

                $(data).hide();
                // append it to the div
                $("div.result").append(data);
                // fade it in
                $(data).fadeIn();
                // increment the page
                ajaxpagination.page++;

              }

              checkTotal();

            }
          });
        });
      }
    },
    'post_type_archive_min_recognition': {
      init: function() {

        function checkTotal() {
          var projectCount = ajaxpagination.total,
            currentProjects = $('.cell').length;
          if(projectCount <= currentProjects ) {
            $('#load-more-recognition-btn').hide();
          }
        }

        checkTotal();

        $(document).on( 'click', '#load-more-recognition-btn', function( event ) {

          event.preventDefault();

          $.ajax({
            url: ajaxpagination.ajaxurl,
            type: 'post',
            data: {
              action: 'ajax_pagination_recognition',
              page: ajaxpagination.page
            },

            beforeSend: function() {

              var loadMoreBtn = $("#load-more-recognition-btn"),
                code = '<span> Loading ' +
                  '<span class="glyphicon glyphicon-refresh spinner"></span>' +
                  '</span>';

              loadMoreBtn.addClass('loading');
              loadMoreBtn.removeClass("default");
              loadMoreBtn.html(code);

            },

            success: function( result ) {

              var data = $(result),
                loadMoreBtn = $("#load-more-recognition-btn"),
                code;

              // check to make sure anything was returned from the query
              if(data.length) {

                // if there's data
                code = '<span class="animate"> Load More ' +
                  '<span class="first animation"></span> ' +
                  '<span class="second animation"></span> ' +
                  '</span>';

                loadMoreBtn.addClass("default");
                loadMoreBtn.removeClass("loading");
                loadMoreBtn.html(code);

                $(data).hide();
                // append it to the div
                $("div.result").append(data);
                // fade it in
                $(data).fadeIn();
                // increment the page
                ajaxpagination.page++;
              }

              checkTotal();

            }
          });
        });
      }
    },
    'tax_min_design_project_tags': {
      init: function() {

        function checkTotal() {
          var projectCount = ajaxpagination.total,
            currentProjects = $('.project-tile-container').length;
          if(projectCount <= currentProjects ) {
            $('#load-more-btn').hide();
          }
        }

        checkTotal();

        $(document).on( 'click', '#load-more-btn', function( event ) {

          event.preventDefault();

          $.ajax({
            url: ajaxpagination.ajaxurl,
            type: 'post',
            data: {
              action: 'ajax_pagination_project_tag',
              page: ajaxpagination.page,
              termID: ajaxpagination.term
            },

            beforeSend: function() {

              var loadMoreBtn = $("#load-more-btn"),
                code = '<span> Loading ' +
                  '<span class="glyphicon glyphicon-refresh spinner"></span>' +
                  '</span>';

              loadMoreBtn.addClass('loading');
              loadMoreBtn.removeClass("default");
              loadMoreBtn.html(code);

            },

            success: function( result ) {

              var data = $(result),
                loadMoreBtn = $("#load-more-btn"),
                code;

              // check to make sure anything was returned from the query
              if(data.length) {

                // if there's data
                code = '<span class="animate"> Load More ' +
                  '<span class="first animation"></span> ' +
                  '<span class="second animation"></span> ' +
                  '</span>';

                loadMoreBtn.addClass("default");
                loadMoreBtn.removeClass("loading");
                loadMoreBtn.html(code);

                $(data).hide();
                // append it to the div
                $("div.result").append(data);
                // fade it in
                $(data).fadeIn();
                // increment the page
                ajaxpagination.page++;
              }

              checkTotal();

            }
          });
        });
      }
    },
    'single_min_design_project': {
      init: function() {
        let controller, headerTween, headerEls, trigger, header, HeaderScene;
        
        gsap.defaults({ease: "ease-in-out"});
        gsap.set(".fade-in", {
          y: 100,
          opacity: 0
        });
        
        function initTweens() {

          if (typeof controller !== 'undefined') {
            controller = controller.destroy(true);
            controller = new ScrollMagic.Controller();
          } else {
            controller = new ScrollMagic.Controller();
          }
          // without resetting the scenes

          // header meta tween
          trigger = $('.intro-trigger')[0];
          header = $('header.project-header')[0];

          // console.log(header);
          headerEls = $(header).find('.intro, ul.project-details li, .project-description, .project-shares');

          headerTween = gsap.from(headerEls,{ duration: 0.5, y: 50 }, 0.2); // opacity: 0,

          ScrollTrigger.batch(".fade-in", {
            start: "top bottom-=100px",
            onEnter: batch => gsap.to(batch, {opacity: 1, y: 0, backgroundSize:"100%", stagger: 0.15}),
            onLeaveBack:batch => gsap.to(batch, {opacity: 0, y: 100, backgroundSize:"0%", stagger: 0.1})
          });

          // when ScrollTrigger does a refresh(), it maps all the positioning data which 
          // factors in transforms, but in this example we're initially setting all the ".box"
          // elements to a "y" of 100 solely for the animation in which would throw off the normal 
          // positioning, so we use a "refreshInit" listener to reset the y temporarily. When we 
          // return a gsap.set() in the listener, it'll automatically revert it after the refresh()!
          ScrollTrigger.addEventListener("refreshInit", () => gsap.set(".fade-in", {y: 0, backgroundSize:"0%"}));

          // header meta tween
          // HeaderScene = new ScrollMagic.Scene({
          //   triggerElement: trigger,
          //   triggerHook: "onEnter",
          //   offset: 50
          // })
          //   .setTween(headerTween)
          //   .addTo(controller);

          let singleImage = $('.image-text'),
            imageInset = $('.image-inset'),
            flipbookAnimation = $('.flipbook-animation'),
            imageFullWidth = $('.image-full-width'),
            imagePullquote = $('.image-pullquote');

          // let captions = $('.caption, .quote');
          // $.each(captions, function() {
          //   let tween = gsap.from(this, { duration: 0.5, opacity: 0, y: 50}),
          //     offset;

          //   if($(this).hasClass('flipbook')) {
          //     offset = 0;
          //   } else if($(this).hasClass('pullquote')) {
          //     offset = 100;
          //   } else {
          //     offset = 50;
          //   }

          //   let scene = new ScrollMagic.Scene({
          //     triggerElement: this,
          //     triggerHook: "onEnter",
          //     offset: offset
          //   }).setTween(tween)
          //     .addTo(controller);
          // }
          // );

          $.each(flipbookAnimation, function(index, value) {
            var first = $(this).find('.first-image img');
            first.css({transition : 'border 0.2s ease-in-out', boxSizing : 'content-box'});

            var trigger = $(this).parent()[0],
              allImages = $(this).find('.animated-image').not('.first-image'),
              sequence = $(this),
              numImages = allImages.length +1,
              tween = new gsap.staggerFrom($(allImages), { duration: 1, autoAlpha: 0 }, 1),
              duration = $(this).height() * (numImages/4);


            new ScrollMagic.Scene({triggerElement: trigger, triggerHook: "onLeave" , duration: duration})
              .setTween(tween)
              .addTo(controller);

            var pinScene = new ScrollMagic.Scene({triggerElement: trigger, triggerHook: "onLeave", duration: duration})
              .setPin(sequence[0])
              .addTo(controller);

            // animation tween - change background color on enter / exit

            var oldColor = null;
            var flipbookContainer = $('.animation-sequence');

            var colorFade = '#000';

            flipbookContainer.css({transition: 'background-color 0.2s ease-in-out'});

            pinScene.on('enter', function() {

              oldColor = flipbookContainer.css("backgroundColor");
              flipbookContainer.css({background: colorFade});

            });

            pinScene.on('leave', function() {

              flipbookContainer.css({background: oldColor});

            });

          });

        }


        Barba.Pjax.start();
        Barba.Prefetch.init();
        // disable barba on all links but the next projects
        Barba.Pjax.originalPreventCheck = Barba.Pjax.preventCheck;

        Barba.Pjax.preventCheck = function(evt, element) {
          if (!Barba.Pjax.originalPreventCheck(evt, element)) {
            return false;
          }
          if (!$(element).hasClass('use-barba')) {
            return false;
          }
          return true;
        };

        initTweens();

        // var linkClicked = false;

        var linkClicked = false;

        Barba.Dispatcher.on('linkClicked', function() {
          linkClicked = true;
          console.log(linkClicked);
        });

        Barba.Dispatcher.on('transitionCompleted', function() {
          linkClicked = false;
          console.log(linkClicked);
          initTweens();
        });

        // set up new tweens when page is ready.
        // Barba.Dispatcher.on('newPageReady', function(current, prev, container) {
        //   initTweens(container);
        //   //container is the html of the new `barba-container`
        // });

        // if regular link clicked.
        var transitionAnimation = Barba.BaseTransition.extend({

          start: function() {
              // promise resolves when new container is loaded and the out transition is done.
              // then this.fadeIn runs.

              Promise
                .all([this.newContainerLoading, this.startTransition()])
                .then(this.fadeIn.bind(this));

          },

          startTransition: function() {

            return new Promise(function(resolve) {

              var outTransition = new gsap.timeline();
              var title = $('#next-project');
              outTransition
                .to($('#entry-content, h2.next-project-header'), {opacity:0, duration: 1})
              //  .to($('h2.next-project-header'), {x: 30, duration: 1})
                .set(title, { height: "auto", onComplete: function() {
                  resolve();
                  }});
            });

          },
          title : $('body #next-project'),

          findPosition : function(oldContainer, newContainer) {

            var positionWhenClicked = $(oldContainer).find('h2.next-project-header')[0].getBoundingClientRect().top;

            var nextProjectHeaderHeight = $(oldContainer).find('h2.next-project-header').outerHeight();




            //space at top of new page.
            var projectCategoryHeight = $(newContainer).find('#project-category-list').outerHeight();
            var navHeight = $('#navigation').outerHeight();
            var topSpace = projectCategoryHeight + navHeight;



            var moveTo = topSpace - positionWhenClicked;

            if(window.matchMedia("(min-width: 992px)").matches) {
              return moveTo + 2;
            }
            else if(window.matchMedia("(min-width: 768px)").matches) {
              return moveTo + 2;
            } else {
              return moveTo;
            }
          },

          fadeIn: function() {



            var inTransition = new gsap.timeline(),
                _this = this,
                $newContainer = $(this.newContainer);

            inTransition.to($(this.oldContainer).find('#next-project')[0], {duration: 1, y:this.findPosition(this.oldContainer, $newContainer), ease: "power2.easeOut", onComplete: function() {

              }}); //
            inTransition.add( function() {
              $(window).scrollTop(0);
              console.log('window scrolled');
            });
            inTransition.set($(this.newContainer).find('#project-category-list'), {opacity: 0});
            inTransition.set($(this.oldContainer), { display: "none", onComplete: function() {
              console.log('old container display none');
              }});

            inTransition.set($newContainer, {visibility: "visible", onComplete: function() {
              console.log('new container display visible');
              }});

            inTransition.to($(this.newContainer).find('#project-category-list'), {duration: 0.3, opacity: 1});

            //inTransition.to($('body #project-category-list'), {duration: 1, opacity: 1});

            inTransition.invalidate();

            inTransition.to($newContainer, {
              duration: 0.1, 
              onComplete: function() {
                _this.done();
                console.log("done");
              }
            });
            linkClicked = false;

          }

        });

        var hideShowTransition = Barba.BaseTransition.extend({
          start: function() {
            // promise resolves when new container is loaded and the out transition is done.
            // then this.fadeIn runs.

            Promise
              .all([this.newContainerLoading, this.startTransition()])
              .then(this.fadeIn.bind(this));

          },
          startTransition : function() {
            return new Promise(function(resolve) {

                gsap.to($(this.oldContainer), {duration: 0.4, opacity:0, onComplete: function() {
                  resolve();
                  }});

            });
          },
          fadeIn: function() {
            var _this = this;

            var inTransition = new gsap.timeline();

            inTransition.set($(this.oldContainer), { display: "none", onComplete: function() {
                console.log('old container display none');
              }});
            inTransition.add( function() {
              $(window).scrollTop(0);
            });
            inTransition.set($(this.newContainer), {visibility: "visible", onComplete: function() {
                console.log('new container display visible');
              }});

            inTransition.to($(this.newContainer), {
              duration: 0.1,
              onComplete: function() {
                _this.done();
                console.log("done");
              }
            });
            linkClicked = false;
          }

        });

        Barba.Pjax.getTransition = function() {
          /**
           * Here you can use your own logic!
           * For example you can use different Transition based on the current page or link...
           */
          return linkClicked ? transitionAnimation : hideShowTransition;

        };




      }
    },

    'about': {
      init: function() {
        var player = new Vimeo.Player($('.video-container iframe')[0]);
      }
    }

  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
